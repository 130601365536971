<template>
  <ResourceDialog
    @close="close"
    @save="submit"
    @save:draft="save"
    ref="dialog"
    :processing="processing"
    :save-button-disabled="!!operatingLog?.submitted_at"
    :title="`Submit ${terms.attendance}`"
    save-button-text="Submit"
    closeable
    draftable
    fullscreen
  >
    <template #form>
      <template v-if="operatingLog">
        <QuestionSet
          v-model="operatingLog"
          :attachment-owner-id="attachmentOwnerId"
          :attachment-owner-type="'OperatingLog'"
          :attachments="attachments"
          :questions="validPublishedQuestions"
          :readonly="!!operatingLog.submitted_at"
          :schema="schema.definition"
          expanded
          flat
          hide-actions
          outlined-sections
          paddingless
          very-dense
        />

        <div
          v-for="(question, index) in validVerificationQuestions"
          :key="['verification', index].join('-')"
        >
          <FormQuestion
            :schema="schema.definition"
            :subtitle="question.verification_subtitle"
            :title="question.verification_title"
            hide-actions
            very-dense
          >
            <AttachmentUploader
              @uploaded="attachments.push($event)"
              :ref="['uploader', question.id].join('')"
              :owner="{
                type: 'OperatingLog',
                id: operatingLog.id,
                tag: question.id,
                tags: [operatingLog.id, question.id],
              }"
              class="mb-4"
            />

            <AttachmentList
              @delete="loadAttachments"
              :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
              class="mb-6"
            />
          </FormQuestion>
        </div>
      </template>
    </template>

    <template #component-actions>
      <template v-if="userCanUnsubmit">
        <v-btn
          @click="unsubmitAttendance"
          :disabled="unsubmitButtonDisabled"
          :loading="processing"
          :ripple="false"
          class="focus-very-visible px-4"
          color="primary"
          data-cy="unsubmit-button"
          size="x-large"
          variant="outlined"
        >
          <span>{{ $t('Unsubmit') }}</span>
        </v-btn>
      </template>
    </template>
  </ResourceDialog>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { ROLES } from '@/shared/assets/constants';
import useRouterHelper from '../composables/useRouterHelper';
import useTerms from '../composables/useTerms';
import useQuestionable from '../composables/useQuestionable';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import useEventBus from '@/shared/composables/useEventBus';

const { updateQuery } = useRouterHelper();
const { terms } = useTerms();
const route = useRoute();
const store = useStore();
const eventBus = useEventBus();

const emit = defineEmits(['change']);

const attachments = ref([]);
const dialog = ref(null);
const ownerId = ref(null);
const ownerDataType = ref('Schema'); // This is constant, but a reactive ref is required for the useQuestionable composable
const operatingLog = ref(null);
const processing = ref(false);
const schema = ref(
  Object.values(store.state.schemas).find((schema) => schema.data_type === 'OperatingLog'),
);

const { loadQuestions, validVerificationQuestions, validPublishedQuestions } = useQuestionable({
  ownerDataType,
  ownerId,
  syncedObject: operatingLog,
});

watch(
  () => route.query?.action,
  async (newVal) => {
    if (newVal === 'editOperatingLog') await open();
  },
  { immediate: true },
);

const attachmentOwnerId = computed(() => {
  return operatingLog.value?.id;
});

const unsubmitButtonDisabled = computed(() => {
  return !operatingLog.value?.submitted_at;
});

const userCanUnsubmit = computed(() => {
  return store.state.role === ROLES.SPECIALIST;
});

onMounted(async () => {
  ownerId.value = schema.value?.id;
  await loadQuestions();
});

async function close() {
  dialog.value?.close();
  await updateQuery({ action: null });
}

function getScopedApi() {
  if (store.state.role === ROLES.MANAGER) return Api.manager;
  if (store.state.role === ROLES.SPECIALIST) return Api.organization;

  return null;
}

function unsubmitAttendance() {
  if (unsubmitButtonDisabled.value || !userCanUnsubmit.value) return;

  return unsubmit();
}

async function loadAttachments() {
  const params = {
    owner_type: 'OperatingLog',
    owner_id: operatingLog.value?.id,
  };
  const resp = await getScopedApi().attachment.index(params);
  attachments.value = resp.data;
}

async function loadOperatingLog(operatingLogId) {
  const resp = await getScopedApi().operating_log.get(operatingLogId);
  operatingLog.value = resp.data;
}

async function open() {
  await loadOperatingLog(route.query.operatingLogId);
  await loadAttachments();

  dialog.value.open();
}

async function save() {
  processing.value = true;
  const params = { custom: operatingLog.value.custom };
  const resp = await getScopedApi().operating_log.update(operatingLog.value?.id, params);
  processing.value = false;
  if (resp?.status !== 200) return false;

  eventBus.chime('Saved');
  return true;
}

async function submit() {
  processing.value = true;
  const params = { submitted: true, custom: operatingLog.value?.custom };
  const resp = await getScopedApi().operating_log.update(operatingLog.value?.id, params);
  processing.value = false;
  if (resp?.status !== 200) return false;

  eventBus.chime('Submitted');
  operatingLog.value = resp.data;
  await close();
  emit('change', operatingLog.value);
  return true;
}

async function unsubmit() {
  processing.value = true;
  const params = { submitted: false };
  const resp = await getScopedApi().operating_log.update(operatingLog.value?.id, params);
  processing.value = false;
  if (resp?.status !== 200) return false;

  eventBus.chime('Unsubmitted');
  operatingLog.value = resp.data;
  emit('change', operatingLog.value);
  return true;
}
</script>
