<template>
  <ResourceDialog
    @close="$emit('close')"
    @save="$emit('save', answer)"
    ref="dialog"
    :max-width="600"
    :processing="processing"
    closeable
  >
    <template #form>
      <div class="ta-center">
        <QuestionWithField
          v-model="answer"
          :elevation="0"
          :hide-actions="true"
          :outlined="false"
          :paddingless="true"
          :processing="processing"
          :question="question"
          :schema="schemaDefinition"
        />
      </div>
    </template>
  </ResourceDialog>
</template>

<script>
import QuestionWithField from '@/shared/components/form/QuestionWithField.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    QuestionWithField,
    ResourceDialog,
  },

  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    schemaDefinition: {
      type: Object,
      default: null,
    },
  },

  emits: ['close', 'save'],

  data() {
    return {
      answer: this.getBlankAnswer(),
      question: null,
    };
  },

  methods: {
    close() {
      this.$refs.dialog.close();
    },

    getBlankAnswer() {
      return { custom: {} };
    },

    open({ question }) {
      this.answer = this.getBlankAnswer();
      this.question = question;
      this.$refs.dialog.open();
    },
  },
};
</script>
