<template>
  <td
    :class="cellClasses"
    :title="answer"
  >
    <div
      @click="handleClick"
      :class="classes"
      class="rounded d-flex align-center justify-center h-100pc"
    >
      <template v-if="answer != null">
        <DefinedPropertyElement
          :attribute-name="question.synced_attributes[0].name"
          :definition="schemaDefinition"
        >
          <template #default="{ properties }">
            <template v-if="properties.type == 'boolean'">
              <template v-if="answer == true">
                <span v-text="question.true_label" />
              </template>
              <template v-else-if="answer == false">
                <span v-text="question.false_label" />
              </template>
            </template>
            <template v-else>
              <span v-text="answer" />
            </template>
          </template>
        </DefinedPropertyElement>
      </template>
    </div>
  </td>
</template>

<script setup>
import DefinedPropertyElement from '@/shared/components/DefinedPropertyElement.vue';

const props = defineProps({
  attendanceLog: {
    type: Object,
    default: null,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  operatingLog: {
    type: Object,
    default: null,
  },
  queuedAttendanceLogQuestionId: {
    type: String,
    default: null,
  },
  queuedAttendanceLogAnswers: {
    type: Array,
    default: () => [],
  },
  question: {
    type: Object,
    default: null,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  schemaDefinition: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['click']);

const classes = computed(() => {
  const ary = [];
  if (answer.value == null) ary.push('bg-very-light-grey');

  if (queued.value) ary.push('shadowed');

  if (!props.readonly) ary.push('pointer');

  return ary.join(' ');
});

const cellClasses = computed(() => {
  let classes = 'bb-1 py-2 br-1 bc-very-light-grey';
  if (props.invalid) {
    classes += ' bg-red';
  }
  return classes;
});

const answer = computed(() => {
  return props.attendanceLog.custom[field.value];
});

const field = computed(() => {
  return (props.question.synced_attributes[0]?.name?.split('.') || [])[1];
});

const queued = computed(() => {
  return (
    props.queuedAttendanceLogQuestionId === props.question.id &&
    props.queuedAttendanceLogAnswers.includes(props.attendanceLog.id)
  );
});

function handleClick() {
  if (props.readonly || queued.value) return;

  emit('click');
}
</script>
